/* eslint-disable react/function-component-definition */
import React from 'react';
import { Row, Col } from 'reactstrap';
import auth0Client from '../../Auth/Auth';

import AddStudent from '../AddStudent/AddStudent';
import StudentList from '../StudentList/StudentList';
import StudentSearch from '../StudentSearch/StudentSearch';

const StudentAdmin = () => {
  const profile = auth0Client.getProfile();
  const namespace = 'https://api.llusbh.com/role';
  const userType = profile[namespace][0];

  return (
    <div>
      <Row>
        <Col>
          <StudentSearch />
          {(userType === 'admin' || userType === 'staff') && <AddStudent />}
          <StudentList />
        </Col>
      </Row>
    </div>
  );
};

export default StudentAdmin;

/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
// import { Link } from 'react-router-dom';
import { Row, Col, Button, Form, FormGroup, Input, Label } from 'reactstrap';

import Widget from '../Widget/Widget';

import iUser from '../../images/user.png';
import iDave from '../../images/sbh-dave-vermeersch.png';
import iKendal from '../../images/sbh-kendal-boyd.png';
import iHolly from '../../images/sbh-holly-morrell.png';
// import iAdam from '../../images/sbh-adam-arechiga.png';
import iHector from '../../images/sbh-hector-betancourt.png';
import iMaya from '../../images/sbh-maya-boustani.png';
import iColleen from '../../images/sbh-colleen-brenner.png';
import iPatricia from '../../images/sbh-patricia-flynn.png';
// import iPaul from '../../images/sbh-paul-haerich.png';
import iRichard from '../../images/sbh-richard-hartman.png';
import iGrace from '../../images/sbh-grace-lee.png';
import iKelly from '../../images/sbh-kelly-morton.png';
import iCameron from '../../images/sbh-cameron-neece.png';
import iTori from '../../images/sbh-tori-van-dyk.png';

// import { StudentData, StudentDataPsyD, StudentDataPhD } from '../Account/StudentData';

class StaffAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.generateUUID = this.generateUUID.bind(this);

    this.state = {
      addNewFaculty: false,
      addNewStaff: false,
      firstName: '',
      lastName: '',
      emailAddress: '',
      superuser: '',
      showConfirmation: false,
    };
  }

  onAddNewFacultyBtnClick() {
    this.setState({
      addNewFaculty: !this.state.addNewFaculty,
      addNewStaff: false,
    });
  }

  onAddNewStaffBtnClick() {
    this.setState({
      addNewFaculty: false,
      addNewStaff: !this.state.addNewStaff,
    });
  }

  onSuperUserBtnClick(sectionValue) {
    this.setState({
      superuser: sectionValue,
    });
  }

  onNewUserSubmitBtnClick() {
    this.setState({
      showConfirmation: true,
    });
  }

  onNewUserCancelBtnClick() {
    this.setState({
      addNewFaculty: false,
      addNewStaff: false,
      firstName: '',
      lastName: '',
      emailAddress: '',
      superuser: '',
      showConfirmation: false,
    });
  }

  onAddAnotherUserBtnClick() {
    this.setState({
      addNewFaculty: true,
      firstName: '',
      lastName: '',
      emailAddress: '',
      superuser: '',
      showConfirmation: false,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  generateUUID() {
    // Public Domain/MIT
    let d = new Date().getTime();

    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
      d += performance.now(); // use high-precision timer if available
    }

    // eslint-disable-next-line func-names
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      // eslint-disable-next-line no-bitwise
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      // eslint-disable-next-line no-bitwise
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
  }

  renderFacultyForm() {
    return (
      <div>
        {/* Add Faculty - Q1 */}
        <Form>
          <Row>
            <Col md={{ size: 3, offset: 1 }} className="text-md-right">
              <Label for="firstName">First name</Label>
            </Col>
            <Col sm={5}>
              <Input
                type="text"
                id="firstName"
                name="firstName"
                value={this.state.firstName}
                onChange={this.handleInputChange}
              />
              <br />
            </Col>
          </Row>
          {/* // Add Faculty - Q1 */}
          {/* Add Faculty - Q2 */}
          <Row>
            <Col md={{ size: 3, offset: 1 }} className="text-md-right">
              <Label for="lastName">Last name</Label>
            </Col>
            <Col sm={5}>
              <Input
                type="text"
                id="lastName"
                name="lastName"
                value={this.state.lastName}
                onChange={this.handleInputChange}
              />
              <br />
            </Col>
          </Row>
          {/* // Add Faculty - Q2 */}
          {/* Add Faculty - Q3 */}
          <Row>
            <Col md={{ size: 3, offset: 1 }} className="text-md-right">
              <Label for="email">Email</Label>
            </Col>
            <Col sm={5}>
              <Input
                type="text"
                id="emailAddress"
                name="emailAddress"
                value={this.state.emailAddress}
                onChange={this.handleInputChange}
              />
              <br />
            </Col>
          </Row>
          {/* // Add Faculty - Q3 */}
          {/* Add Faculty - Q4 */}
          <Row>
            <Col md={{ size: 3, offset: 1 }} className="text-md-right">
              <Label for="email">Title</Label>
            </Col>
            <Col sm={5}>
              <Input
                type="text"
                id="title"
                name="title"
                value={this.state.title}
                onChange={this.handleInputChange}
              />
              <br />
            </Col>
          </Row>
          {/* // Add Faculty - Q4 */}
          {/* Add Faculty - Q5 */}
          <Row>
            <Col md={{ size: 3, offset: 1 }} className="text-md-right">
              <Label>Superuser</Label>
            </Col>
            <Col sm={5}>
              <Button
                className="btn-form"
                color={this.state.superuser === 'Yes' ? 'dark' : 'inverse'}
                onClick={() => this.onSuperUserBtnClick('Yes')}
                active={this.state.superuser === 'Yes'}
              >
                Yes
              </Button>
              <Button
                className="btn-form"
                color={this.state.superuser === 'No' ? 'dark' : 'inverse'}
                onClick={() => this.onSuperUserBtnClick('No')}
                active={this.state.superuser === 'No'}
              >
                No
              </Button>
            </Col>
          </Row>
          <p>
            <br />
          </p>
          {/* // Add Student - Q5 */}
          <FormGroup row className="submitBlock">
            <Col md={12} className="text-center">
              <Button color="info" onClick={() => this.onNewUserSubmitBtnClick()} className="ml-xs">
                Add User
              </Button>
              <Button
                color="inverse"
                onClick={() => this.onNewUserCancelBtnClick()}
                className="ml-xs"
              >
                Cancel
              </Button>
            </Col>
          </FormGroup>
        </Form>
      </div>
    );
  }

  renderUserAddedConfirmation() {
    return (
      <div>
        <Row>
          <Col className="text-center">
            <p>
              <h3>User Added!</h3>
            </p>
            <p>A confirmation email containing the login information has been sent to the user. </p>
            <Form>
              <FormGroup row className="submitBlock">
                <Col md={12} className="text-center">
                  <Button
                    color="info"
                    onClick={() => this.onAddAnotherUserBtnClick()}
                    className="ml-xs"
                  >
                    Click Here to Add Another User
                  </Button>
                </Col>
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <p>
          <br />
        </p>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Row>
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-plus" /> Add New Faculty/Staff
                </h5>
              }
            >
              {(() => {
                if (this.state.showConfirmation === false) {
                  return (
                    <div>
                      <Row>
                        <Col>
                          <Button
                            className="btn-form"
                            color={this.state.addNewFaculty === true ? 'dark' : 'inverse'}
                            onClick={() => this.onAddNewFacultyBtnClick()}
                            active={this.state.addNewFaculty === true}
                          >
                            Add Faculty Form
                          </Button>
                          <Button
                            className="btn-form"
                            color={this.state.addNewStaff === true ? 'dark' : 'inverse'}
                            onClick={() => this.onAddNewStaffBtnClick()}
                            active={this.state.addNewStaff === true}
                          >
                            Add Staff Form
                          </Button>
                          <p>
                            <br />
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {(() => {
                            if (
                              this.state.addNewFaculty === true ||
                              this.state.addNewStaff === true
                            ) {
                              return this.renderFacultyForm();
                            }
                          })()}
                        </Col>
                      </Row>
                    </div>
                  );
                }
                return this.renderUserAddedConfirmation();
              })()}
            </Widget>
            <Widget
              title={
                <h5>
                  {' '}
                  <i className="fa fa-align-left" /> Faculty/Staff
                </h5>
              }
            >
              <Row className="mt-4">
                <Col sm={6}>
                  <h5 className="tab-header">Faculty</h5>
                  <ul className="newsList newsListNoHover">
                    <li key={this.generateUUID()}>
                      <img src={iDave} alt="" className="rounded-circle float-left" />
                      <div className="newsItemInfo">
                        <div className="name">
                          <a href="#">David A. Vermeersch, PhD</a>
                        </div>
                        <div className="position">
                          Chair, Department of Psychology
                          <br />
                          Professor, Psychology
                        </div>
                      </div>
                    </li>
                    <li key={this.generateUUID()}>
                      <img src={iKendal} alt="" className="rounded-circle float-left" />
                      <div className="newsItemInfo">
                        <div className="name">
                          <a href="#">Kendal C. Boyd, PhD, MA</a>
                        </div>
                        <div className="position">
                          Associate Chair, Department of Psychology
                          <br />
                          Associate Professor, Psychology
                          <br />
                          Director of Clinical Training, PsyD Program
                        </div>
                      </div>
                    </li>
                    <li key={this.generateUUID()}>
                      <img src={iColleen} alt="" className="rounded-circle float-left" />
                      <div className="newsItemInfo">
                        <div className="name">
                          <a href="#">Colleen A. Brenner, PhD</a>
                        </div>
                        <div className="position">
                          Professor, Psychology
                          <br />
                          Director of Clinical Training, PhD Program
                        </div>
                      </div>
                    </li>
                    <li key={this.generateUUID()}>
                      <img src={iHector} alt="" className="rounded-circle float-left" />
                      <div className="newsItemInfo">
                        <div className="name">
                          <a href="#">Hector M. Betancourt, PhD, MA</a>
                        </div>
                        <div className="position">Distinguished Professor, Psychology</div>
                      </div>
                    </li>
                    <li key={this.generateUUID()}>
                      <img src={iMaya} alt="" className="rounded-circle float-left" />
                      <div className="newsItemInfo">
                        <div className="name">
                          <a href="#">Maya Boustani, PhD, MS</a>
                        </div>
                        <div className="position">Associate Professor, Psychology</div>
                      </div>
                    </li>
                    <li key={this.generateUUID()}>
                      <img src={iPatricia} alt="" className="rounded-circle float-left" />
                      <div className="newsItemInfo">
                        <div className="name">
                          <a href="#">Patricia M. Flynn, PhD, MPH</a>
                        </div>
                        <div className="position">Associate Professor, Psychology</div>
                      </div>
                    </li>
                    <li key={this.generateUUID()}>
                      <img src={iRichard} alt="" className="rounded-circle float-left" />
                      <div className="newsItemInfo">
                        <div className="name">
                          <a href="#">Richard E. Hartman, PhD</a>
                        </div>
                        <div className="position">Professor, Psychology</div>
                      </div>
                    </li>
                    <li key={this.generateUUID()}>
                      <img src={iGrace} alt="" className="rounded-circle float-left" />
                      <div className="newsItemInfo">
                        <div className="name">
                          <a href="#">Grace J. Lee, PhD, MA</a>
                        </div>
                        <div className="position">Associate Professor, Psychology</div>
                      </div>
                    </li>
                    <li key={this.generateUUID()}>
                      <img src={iHolly} alt="" className="rounded-circle float-left" />
                      <div className="newsItemInfo">
                        <div className="name">
                          <a href="#">Holly E. R. Morrell, PhD, MA</a>
                        </div>
                        <div className="position">Professor, Psychology</div>
                      </div>
                    </li>
                    <li key={this.generateUUID()}>
                      <img src={iKelly} alt="" className="rounded-circle float-left" />
                      <div className="newsItemInfo">
                        <div className="name">
                          <a href="#">Kelly R. Morton, PhD, MA</a>
                        </div>
                        <div className="position">
                          Professor, Psychology
                          <br />
                          Professor, Family Medicine, School of Medicine
                        </div>
                      </div>
                    </li>
                    <li key={this.generateUUID()}>
                      <img src={iUser} alt="" className="rounded-circle float-left" />
                      <div className="newsItemInfo">
                        <div className="name">
                          <a href="#">Aarti Nair, PhD, MA</a>
                        </div>
                        <div className="position">Assistant Professor, Psychology</div>
                      </div>
                    </li>
                    <li key={this.generateUUID()}>
                      <img src={iCameron} alt="" className="rounded-circle float-left" />
                      <div className="newsItemInfo">
                        <div className="name">
                          <a href="#">Cameron L. Neece, PhD, MA</a>
                        </div>
                        <div className="position">Professor, Psychology</div>
                      </div>
                    </li>
                    <li key={this.generateUUID()}>
                      <img src={iUser} alt="" className="rounded-circle float-left" />
                      <div className="newsItemInfo">
                        <div className="name">
                          <a href="#">David B. Newman, PhD, MA</a>
                        </div>
                        <div className="position">Assistant Professor, Psychology</div>
                      </div>
                    </li>
                    <li key={this.generateUUID()}>
                      <img src={iUser} alt="" className="rounded-circle float-left" />
                      <div className="newsItemInfo">
                        <div className="name">
                          <a href="#">Bridgette J. Peteet, PhD, MA</a>
                        </div>
                        <div className="position">Professor, Psychology</div>
                      </div>
                    </li>
                    <li key={this.generateUUID()}>
                      <img src={iTori} alt="" className="rounded-circle float-left" />
                      <div className="newsItemInfo">
                        {' '}
                        <div className="name">
                          <a href="#">Tori R. Van Dyk, PhD, MA</a>
                        </div>
                        <div className="position">Associate Professor, Psychology</div>
                      </div>
                    </li>
                    {/* <li key={this.generateUUID()}>
                      <img src={iAdam} alt="" className="float-left rounded-circle" />
                      <div className="newsItemInfo">
                        <div className="name">
                          <a href="#">Adam L. Aréchiga, PsyD, DrPH, MA</a>
                        </div>
                        <div className="position">
                          Associate Dean for Academic and Student Affairs, School of Behavioral
                          Health
                          <br />
                          Associate Professor, Psychology
                        </div>
                      </div>
                    </li> */}
                  </ul>
                </Col>
                <Col sm={6}>
                  <h5 className="tab-header">Staff</h5>
                  <ul className="newsList newsListNoHover">
                    <li key={this.generateUUID()}>
                      <img src={iUser} alt="" className="rounded-circle float-left" />
                      <div className="newsItemInfo">
                        <div className="name">
                          <a href="/forms/staffProfile">Lesley Taylor</a>
                        </div>
                        <div className="position">
                          Senior Administrative Assistant to the Chair
                          <br />
                          Clinical Training Coordinator
                        </div>
                      </div>
                    </li>
                    <li key={this.generateUUID()}>
                      <img src={iUser} alt="" className="rounded-circle float-left" />
                      <div className="newsItemInfo">
                        <div className="name">
                          <a href="/forms/staffProfile">Karen Saul</a>
                        </div>
                        <div className="position">
                          Senior Administrative Assistant
                          <br />
                          Admissions
                        </div>
                      </div>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Widget>
          </Col>
        </Row>
        <p>
          <br />
        </p>
      </div>
    );
  }
}

export default StaffAdmin;

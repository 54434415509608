/* eslint-disable react/no-unused-state */
import React from 'react';
import { Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import axios from 'axios';
import auth0Client from '../../Auth/Auth';

import Widget from '../Widget/Widget';

import '../Forms/forms.scss';

// import loadingSpinnerRed from '../../images/DualRing-1s-200px.svg';

const saveFile = async (blob, suggestedName) => {
  // Feature detection. The API needs to be supported
  // and the app not run in an iframe.
  const supportsFileSystemAccess =
    'showSaveFilePicker' in window &&
    (() => {
      try {
        return window.self === window.top;
      } catch {
        return false;
      }
    })();
  // If the File System Access API is supported…
  if (supportsFileSystemAccess) {
    try {
      // Show the file save dialog.
      const handle = await window.showSaveFilePicker({
        suggestedName,
      });
      // Write the blob to the file.
      const writable = await handle.createWritable();
      await writable.write(blob);
      await writable.close();
      return;
    } catch (err) {
      // Fail silently if the user has simply canceled the dialog.
      if (err.name !== 'AbortError') {
        // eslint-disable-next-line no-console
        console.error(err.name, err.message);
        return;
      }
    }
  }
  // Fallback if the File System Access API is not supported…
  // Create the blob URL.
  const blobURL = URL.createObjectURL(blob);
  // Create the `<a download>` element and append it invisibly.
  const a = document.createElement('a');
  a.href = blobURL;
  a.download = suggestedName;
  a.style.display = 'none';
  document.body.append(a);
  // Programmatically click the element.
  a.click();
  // Revoke the blob URL and remove the element.
  setTimeout(() => {
    URL.revokeObjectURL(blobURL);
    a.remove();
  }, 1000);
};

const checkAuth = (func, params) => {
  if (auth0Client.isAuthenticated()) {
    func.apply(this, params);
  } else {
    auth0Client.silentAuth().then(func.apply(this, params));
  }
};

class Reporting extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    // this.handleMultipleInputChange = this.handleMultipleInputChange.bind(this);
    this.onSubmitBtnClick = this.onSubmitBtnClick.bind(this);
    this.getXLS = this.getXLS.bind(this);
    this.getFormYears = this.getFormYears.bind(this);
    this.getFormTypes = this.getFormTypes.bind(this);
    this.getFormTerms = this.getFormTerms.bind(this);
    // this.getFormStudents = this.getFormStudents.bind(this);

    this.state = {
      formYears: [],
      formTypes: [],
      formTerms: [],
      selectedForm: '',
      selectedYear: 0,
      selectedTerm: '',
      allStudents: 'Yes',
      // selectedStudents: [],
      // loading: false,
    };
  }

  componentDidMount() {
    checkAuth(this.getFormYears);
  }

  handleInputChange(e) {
    const { target } = e;
    const { name, value } = target;

    this.setState({
      [name]: value,
    });

    if (name === 'selectedYear') {
      checkAuth(this.getFormTypes, [value]);
    }

    if (name === 'selectedForm') {
      const { selectedYear } = this.state;
      checkAuth(this.getFormTerms, [selectedYear, value]);
    }
  }

  // handleMultipleInputChange(e) {
  //   // assuming you initialized the default state to hold selected values
  //   this.setState({
  //     selectedStudents: [].slice.call(e.target.selectedOptions).map((o) => {
  //       return o.value;
  //     }),
  //   });
  // }

  onSubmitBtnClick(e) {
    e.target.blur();
    this.setState({ loading: true });
    this.getXLS();
  }

  getXLS() {
    const { selectedForm, selectedYear, selectedTerm } = this.state;

    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
      responseType: 'blob',
    };

    const url = `${process.env.REACT_APP_API_URL}/form/export`;

    axios
      .get(
        url,
        {
          type: selectedForm,
          academicYear: selectedYear,
          term: selectedTerm,
        },
        config,
      )
      .then((response) => {
        // const downloadURL = window.URL.createObjectURL(response.data);
        // const downloadLink = document.createElement('a');
        // downloadLink.href = downloadURL;
        // downloadLink.setAttribute(
        //   'download',
        //   `${selectedForm}_${selectedYear}-${selectedYear + 1}_${new Date()
        //     .toJSON()
        //     .slice(0, 10)}.xlsx`,
        // );
        // document.body.appendChild(downloadLink);
        // downloadLink.click();

        // // clean up and remove the link
        // downloadLink.parentNode.removeChild(downloadLink);

        const fileName = `${selectedForm}_${selectedYear}-${selectedYear + 1}_${new Date()
          .toJSON()
          .slice(0, 10)}.xlsx`;
        saveFile(response.data, fileName);

        this.setState({ loading: false });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        this.setState({ loading: false });
      });
  }

  getFormYears() {
    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    const url = `${process.env.REACT_APP_API_URL}/form/years`;

    axios
      .get(url, config)
      .then((response) => {
        this.setState({
          formYears: response.data,
          selectedYear: Math.min(...response.data),
        });
        this.getFormTypes(Math.min(...response.data));
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        // if (error.response.status === 401) {
        //   auth0Client.silentAuth().then(this.getFormYears());
        // }
        this.setState({
          formYears: [],
          selectedYear: 0,
          formTypes: [],
          selectedForm: '',
          formTerms: [],
          selectedTerm: '',
        });
      });
  }

  getFormTypes(selectedYear) {
    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    const url = `${process.env.REACT_APP_API_URL}/form/types`;

    axios
      .post(url, { academicYear: selectedYear }, config)
      .then((response) => {
        let selectedForm = [];
        if (response.data.length > 1) {
          // eslint-disable-next-line prefer-destructuring
          selectedForm = response.data[0];
        }
        this.setState({ formTypes: response.data, selectedForm });
        this.getFormTerms(selectedYear, response.data[0]);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        this.setState({ formTypes: [], selectedForm: '', formTerms: [], selectedTerm: '' });
      });
  }

  getFormTerms(selectedYear, selectedForm) {
    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    const url = `${process.env.REACT_APP_API_URL}/form/terms`;

    axios
      .post(url, { academicYear: selectedYear, type: selectedForm }, config)
      .then((response) => {
        let selectedTerm = [];
        if (response.data.length > 1) {
          // eslint-disable-next-line prefer-destructuring
          selectedTerm = response.data[0];
        }
        this.setState({ formTerms: response.data, selectedTerm });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        this.setState({ formTerms: [], selectedTerm: '' });
      });
  }

  render() {
    const {
      formYears,
      formTypes,
      formTerms,
      selectedYear,
      selectedForm,
      selectedTerm,
      allStudents,
      // loading,
    } = this.state;

    return (
      <div>
        <Row>
          {/* Selection */}
          <Col xl={{ size: 10, offset: 1 }} xs={12}>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Reporting
                </h5>
              }
            >
              <FormGroup>
                <Form>
                  {/* Reporting - Q1 */}
                  <FormGroup row>
                    <Label for="selectedYear" md={{ size: 3, offset: 1 }} className="text-md-right">
                      Reporting period
                    </Label>
                    <Col md={5}>
                      <Input
                        type="select"
                        name="selectedYear"
                        id="selectedYear"
                        value={selectedYear}
                        onChange={this.handleInputChange}
                        disabled={formYears.length === 0}
                      >
                        {/* <option value={2023}>2023-2024</option> */}
                        {formYears.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </FormGroup>
                  {/* // Reporting - Q1 */}
                  {/* Reporting - Q2 */}
                  <FormGroup row>
                    <Label for="selectedForm" md={{ size: 3, offset: 1 }} className="text-md-right">
                      Forms
                    </Label>
                    <Col md={5}>
                      <Input
                        type="select"
                        name="selectedForm"
                        id="selectedForm"
                        value={selectedForm}
                        onChange={this.handleInputChange}
                        disabled={formTypes.length === 0}
                      >
                        {formTypes.includes('AS') && <option value="AS">Alumni Survey</option>}
                        {formTypes.includes('ASE') && (
                          <option value="ASE">Annual Student Evaluation</option>
                        )}
                        {formTypes.includes('DSE') && (
                          <option value="DSE">Dissertation Research Evaluation</option>
                        )}
                        {formTypes.includes('PSE') && (
                          <option value="PSE">Practicum Site Evaluation</option>
                        )}
                        {formTypes.includes('SACE') && (
                          <option value="SACE">Semi-Annual Clinical Evaluation</option>
                        )}
                        {formTypes.includes('SACEv2') && (
                          <option value="SACEv2">
                            Semi-Annual Clinical Evaluation (2024 Update)
                          </option>
                        )}
                        {formTypes.includes('SAD') && (
                          <option value="SAD">Student Admission Data</option>
                        )}
                        {formTypes.includes('SPR') && (
                          <option value="SPR">Student Progress Report</option>
                        )}
                        {formTypes.includes('SSS') && (
                          <option value="SSS">Student Satisfaction Survey</option>
                        )}
                      </Input>
                    </Col>
                  </FormGroup>
                  {/* // Reporting - Q2 */}
                  {/* Reporting - Q3 */}
                  <FormGroup row>
                    <Label for="selectedTerm" md={{ size: 3, offset: 1 }} className="text-md-right">
                      Reporting term
                    </Label>
                    <Col md={5}>
                      <Input
                        type="select"
                        name="selectedTerm"
                        id="selectedTerm"
                        value={selectedTerm}
                        onChange={this.handleInputChange}
                        disabled={formTerms.length === 0}
                      >
                        {formTerms.includes('fall') && <option value="fall">Fall</option>}
                        {formTerms.includes('winter') && <option value="winter">Winter</option>}
                        {formTerms.includes('spring') && <option value="spring">Spring</option>}
                        {formTerms.includes('summer') && <option value="summer">Summer</option>}
                      </Input>
                    </Col>
                  </FormGroup>
                  {/* // Reporting - Q3 */}
                  {/* Reporting - Q4 */}
                  <FormGroup row>
                    <Label for="allStudents" md={{ size: 3, offset: 1 }} className="text-md-right">
                      Select all students?
                    </Label>
                    <Col md={5}>
                      <Input
                        type="select"
                        name="allStudents"
                        id="allStudents"
                        value={allStudents}
                        onChange={this.handleInputChange}
                      >
                        <option>Yes</option>
                        <option>Ph.D. Only</option>
                        <option>Psy.D. Only</option>
                        {/* <option>No</option> */}
                      </Input>
                    </Col>
                  </FormGroup>
                  {/* // Reporting - Q4 */}
                  {/* Reporting - Q5 */}
                  {/* {(() => {
                    if (this.state.allStudents === 'No') {
                      return (
                        <FormGroup row>
                          <Label
                            for="selectedStudents"
                            md={{ size: 3, offset: 1 }}
                            className="text-md-right"
                          >
                            Select students
                          </Label>
                          <Col md={5}>
                            <Input
                              type="select"
                              name="selectedStudents"
                              id="selectedStudents"
                              multiple
                              value={this.state.selectedStudents}
                              onChange={this.handleMultipleInputChange}
                            >
                              <option>ALBERTSON, TYSON</option>
                              <option>CHIA, DEXTER</option>
                              <option>GENATO, JORDANNE</option>
                              <option>HARDEMAN, WILLIE</option>
                              <option>NUNEZ, ESMERALDA</option>
                            </Input>
                          </Col>
                        </FormGroup>
                      );
                    }
                  })()} */}
                  {/* // Reporting - Q5 */}
                  {/* // Reporting - Q6 */}
                  <FormGroup row className="submitBlock">
                    <Col md={12} className="text-center">
                      {/* <Button
                        color="info"
                        onClick={this.onSubmitBtnClick}
                        className="ml-xs"
                        disabled={loading}
                      >
                        {!loading && <div>Download</div>}
                        {loading && (
                          <img
                            src={loadingSpinnerRed}
                            alt="loading spinner"
                            className="float-right"
                            style={{ maxWidth: 20 }}
                          />
                        )}
                      </Button> */}
                      <Button
                        color="info"
                        onClick={this.onSubmitBtnClick}
                        className="ml-xs"
                        disabled
                      >
                        Download
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Reporting;

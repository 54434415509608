/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
// import { Link } from 'react-router-dom';
import { Form, Input, InputGroup, InputGroupAddon, Row, Col, Button } from 'reactstrap';
import axios from 'axios';
import auth0Client from '../../Auth/Auth';
import Widget from '../Widget/Widget';

import loadingSpinner from '../../images/Eclipse-1s-200px.svg';
import iFacility from '../../images/facility.png';

function generateUUID() {
  // Public Domain/MIT
  let d = new Date().getTime();

  if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
    d += performance.now(); // use high-precision timer if available
  }

  // eslint-disable-next-line func-names
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    // eslint-disable-next-line no-bitwise
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    // eslint-disable-next-line no-bitwise
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

class FacilitySearch extends React.Component {
  constructor(props) {
    super(props);
    this.onSearchBtnClick = this.onSearchBtnClick.bind(this);
    this.searchFacilities = this.searchFacilities.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderFacilities = this.renderFacilities.bind(this);

    this.state = {
      searchValue: '',
      noResults: false,
      facilityData: [],
      loading: false,
    };
  }

  handleInputChange(e) {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.searchFacilities();
  }

  onSearchBtnClick(e) {
    e.preventDefault();
    e.target.blur();
    this.setState({
      loading: true,
    });
    this.searchClinicalSupervisors();
  }

  searchFacilities() {
    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    const { searchValue } = this.state;
    const url = `${process.env.REACT_APP_API_URL}/facility/search`;

    axios
      .post(url, { searchValue }, config)
      .then((response) => {
        const facilityData = response.data;

        // set noResults to true if no results are returned
        const noResults = !facilityData.length > 0;

        this.setState({ loading: false, facilityData, noResults });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  renderFacilities(idx) {
    const { facilityData } = this.state;
    let filteredFacilities = [];

    if (idx === 'even') {
      filteredFacilities = facilityData.filter(function filterEven(v, i) {
        // check the index is even
        return i % 2 === 0;
      });
    } else {
      filteredFacilities = facilityData.filter(function filterOdd(v, i) {
        // check the index is odd
        return i % 2 !== 0;
      });
    }

    return (
      <div>
        <ul className="newsList newsListNoHover">
          {filteredFacilities.map((facility) => (
            <li key={generateUUID()}>
              <img src={iFacility} alt="" className="rounded-circle float-left" />
              <div className="newsItemInfo">
                <div className="name">
                  <a href="#">{facility.name}</a>
                </div>
                <div className="position">{facility.department}</div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  render() {
    const { loading, searchValue, noResults } = this.state;
    return (
      <div>
        <Widget
          title={
            <h5>
              <i className="fa fa-search" /> Facility Search
            </h5>
          }
        >
          <Row className="mt-4">
            <Col md={8}>
              <Form onSubmit={this.handleSubmit}>
                <InputGroup>
                  <Input
                    type="text"
                    id="searchValue"
                    name="searchValue"
                    value={searchValue}
                    onChange={this.handleInputChange}
                  />
                  <InputGroupAddon addonType="append">
                    <Button color="inverse" onClick={this.onSearchBtnClick}>
                      Search
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </Form>
            </Col>
            <Col md={4} />
          </Row>
          {loading && (
            <div>
              <Row className="text-center">
                <Col>
                  <img src={loadingSpinner} alt="loading spinner" className="img-responsive" />
                </Col>
              </Row>
            </div>
          )}
          {!loading && (
            <div>
              <Row className="mt-4">
                <Col md={6}>{this.renderFacilities('even')}</Col>
                <Col md={6}>{this.renderFacilities('odd')}</Col>
              </Row>
            </div>
          )}
          {noResults && (
            <Row>
              <Col>
                <p className="text-danger">No results found.</p>
              </Col>
            </Row>
          )}
        </Widget>
      </div>
    );
  }
}

export default FacilitySearch;

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Progress,
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
} from 'reactstrap';
import axios from 'axios';
import auth0Client from '../../../Auth/Auth';

import Widget from '../../Widget/Widget';

import '../forms.scss';

import loadingSpinner from '../../../images/Eclipse-1s-200px.svg';

class SAD extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleNumberInputChange = this.handleNumberInputChange.bind(this);
    this.onFrmBtnClick = this.onFrmBtnClick.bind(this);
    this.onFrmBtnToggle = this.onFrmBtnToggle.bind(this);
    this.onSectionBtnClick = this.onSectionBtnClick.bind(this);
    this.getForm = this.getForm.bind(this);
    this.saveForm = this.saveForm.bind(this);
    this.validateGPA = this.validateGPA.bind(this);
    this.renderDemographics = this.renderDemographics.bind(this);
    this.renderUndergraduateEducation = this.renderUndergraduateEducation.bind(this);
    this.renderGraduateEducation = this.renderGraduateEducation.bind(this);
    this.renderGREInformation = this.renderGREInformation.bind(this);
    this.renderReligion = this.renderReligion.bind(this);
    this.renderReadout = this.renderReadout.bind(this);

    this.state = {
      formData: [
        {
          id: 'Demographics',
          data: [
            {
              id: 'gender',
              value: '',
            },
            {
              id: 'ethnicity',
              value: '',
            },
            {
              id: 'race',
              value: '',
            },
            {
              id: '1stGenCollege',
              value: '',
            },
            {
              id: 'subjectToADA',
              value: '',
            },
            {
              id: 'foreignNational',
              value: '',
            },
            {
              id: 'interviewScore',
              value: '',
            },
          ],
        },
        {
          id: 'Undergraduate Education',
          data: [
            {
              id: 'undergraduateInstitution',
              value: '',
            },
            {
              id: 'undergraduateDegree',
              value: '',
            },
            {
              id: 'undergraduateMajor',
              value: '',
            },
            {
              id: 'undergraduateDegreeYear',
              value: '',
            },
            {
              id: 'undergraduateGPA',
              value: '',
            },
          ],
        },
        {
          id: 'Graduate Education',
          data: [
            {
              id: 'graduateInstitution',
              value: '',
            },
            {
              id: 'graduateDegree',
              value: '',
            },
            {
              id: 'graduateMajor',
              value: '',
            },
            {
              id: 'graduateDegreeYear',
              value: '',
            },
            {
              id: 'graduateGPA',
              value: '',
            },
          ],
        },
        {
          id: 'GRE Information',
          data: [
            {
              id: 'verbal',
              value: [
                {
                  id: 'Score',
                  value: '',
                },
                {
                  id: 'Percentile',
                  value: '',
                },
              ],
            },
            {
              id: 'quantitative',
              value: [
                {
                  id: 'Score',
                  value: '',
                },
                {
                  id: 'Percentile',
                  value: '',
                },
              ],
            },
            {
              id: 'analyticalWriting',
              value: [
                {
                  id: 'Score',
                  value: '',
                },
                {
                  id: 'Percentile',
                  value: '',
                },
              ],
            },
            {
              id: 'advancedPsychology',
              value: [
                {
                  id: 'Score',
                  value: '',
                },
                {
                  id: 'Percentile',
                  value: '',
                },
              ],
            },
          ],
        },
        {
          id: 'Religious Affiliation',
          data: [
            {
              id: 'SDA',
              value: '',
            },
            {
              id: 'religion',
              value: '',
            },
          ],
        },
      ],
      ethnicitySelect: 'Hispanic/Latino',
      raceSelect: 'American Indian',
      interviewScore: 0,
      undergraduateInstitution: '',
      undergraduateDegree: '',
      undergraduateMajor: '',
      undergraduateDegreeYear: new Date().getFullYear(),
      undergraduateGPA: '',
      undergraduateGPAError: false,
      undergraduateGPAErrorMsg: '',
      graduateInstitution: '',
      graduateDegree: '',
      graduateMajor: '',
      graduateDegreeYear: new Date().getFullYear(),
      graduateGPA: '',
      graduateGPAError: false,
      graduateGPAErrorMsg: '',
      greVerbalScore: 0,
      grePercentile: 0,
      greQuantitativeScore: 0,
      greQuantitativePercentile: 0,
      greAnalyticalWritingScore: 0,
      greAnalyticalWritingPercentile: 0,
      greAdvPsyScore: 0,
      greAdvPsyPercentile: 0,
      religion: '',
      studentID: '',
      studentName: '',
      currentSection: 0,
      loading: true,
      error: false,
    };
  }

  componentDidMount() {
    this.getForm();
  }

  handleInputChange(e) {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  handleNumberInputChange(e) {
    const { target } = e;
    const { name, value } = target;

    // only allow numbers 0 through 9 and backspace
    if (target.validity.valid) {
      this.setState({ [name]: value });
    } else if (value === '') {
      this.setState({ [name]: value });
    }
  }

  onFrmBtnClick(e, sectionID, questionID, answer) {
    e.preventDefault();
    e.target.blur();

    const newState = {
      // eslint-disable-next-line react/destructuring-assignment, react/no-access-state-in-setstate
      ...this.state.formData,
    };

    newState[sectionID].data[questionID].value = answer;

    this.setState({
      formData: newState,
    });
  }

  onFrmBtnToggle(e, sectionID, questionID) {
    e.preventDefault();
    e.target.blur();

    const newState = {
      // eslint-disable-next-line react/destructuring-assignment, react/no-access-state-in-setstate
      ...this.state.formData,
    };

    newState[sectionID].data[questionID].value *= -1;

    this.setState({
      formData: newState,
    });
  }

  onSectionBtnClick(e, sectionValue) {
    e.preventDefault();
    e.target.blur();
    window.scrollTo(0, 0);

    const { currentSection } = this.state;
    const newSectionID = currentSection + sectionValue;

    this.setState({
      currentSection: newSectionID,
    });
  }

  getForm() {
    const {
      match: { params },
    } = this.props;
    const url = `${process.env.REACT_APP_API_URL}/form/${params.formid}`;

    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        // check if data is valid
        const { data, status, student } = response.data;
        const { _id } = student;
        const studentName = `${student.firstName} ${student.lastName}`;

        if (status !== 'new') {
          let {
            formData,
            ethnicitySelect,
            raceSelect,
            interviewScore,
            undergraduateInstitution,
            undergraduateDegree,
            undergraduateMajor,
            undergraduateDegreeYear,
            undergraduateGPA,
            graduateInstitution,
            graduateDegree,
            graduateMajor,
            graduateDegreeYear,
            graduateGPA,
            greVerbalScore,
            grePercentile,
            greQuantitativeScore,
            greQuantitativePercentile,
            greAnalyticalWritingScore,
            greAnalyticalWritingPercentile,
            greAdvPsyScore,
            greAdvPsyPercentile,
            religion,
            currentSection,
          } = this.state;

          // check if data was returned in a array
          if (data.length === 5) {
            formData = data;
          } else {
            // eslint-disable-next-line prefer-destructuring
            formData = data[0];
          }

          // * Demographics
          if (formData[0].data[1].value !== '') {
            ethnicitySelect = formData[0].data[1].value;
          }
          if (formData[0].data[2].value !== '') {
            raceSelect = formData[0].data[2].value;
          }
          interviewScore = formData[0].data[6].value;

          // * Undergraduate Education
          undergraduateInstitution = formData[1].data[0].value;
          undergraduateDegree = formData[1].data[1].value;
          undergraduateMajor = formData[1].data[2].value;
          undergraduateDegreeYear = formData[1].data[3].value;
          undergraduateGPA = formData[1].data[4].value;

          // * Graduate Education
          graduateInstitution = formData[2].data[0].value;
          graduateDegree = formData[2].data[1].value;
          graduateMajor = formData[2].data[2].value;
          graduateDegreeYear = formData[2].data[3].value;
          graduateGPA = formData[2].data[4].value;

          // * GRE Information
          greVerbalScore = formData[3].data[0].value[0].value;
          grePercentile = formData[3].data[0].value[1].value;
          greQuantitativeScore = formData[3].data[1].value[0].value;
          greQuantitativePercentile = formData[3].data[1].value[1].value;
          greAnalyticalWritingScore = formData[3].data[2].value[0].value;
          greAnalyticalWritingPercentile = formData[3].data[2].value[1].value;
          greAdvPsyScore = formData[3].data[3].value[0].value;
          greAdvPsyPercentile = formData[3].data[3].value[1].value;

          // * Religious Affiliation
          if (formData[4].data[1].value !== '') {
            religion = formData[4].data[1].value;
          }
          // if form is cmmpleted, set currentSection to display readout
          if (status === 'completed') {
            currentSection = 999;
          }

          // form inProgress/completed, set student info and formData
          this.setState({
            formData,
            ethnicitySelect,
            raceSelect,
            interviewScore,
            undergraduateInstitution,
            undergraduateDegree,
            undergraduateMajor,
            undergraduateDegreeYear,
            undergraduateGPA,
            graduateInstitution,
            graduateDegree,
            graduateMajor,
            graduateDegreeYear,
            graduateGPA,
            greVerbalScore,
            grePercentile,
            greQuantitativeScore,
            greQuantitativePercentile,
            greAnalyticalWritingScore,
            greAnalyticalWritingPercentile,
            greAdvPsyScore,
            greAdvPsyPercentile,
            religion,
            studentID: _id,
            studentName,
            currentSection,
            loading: false,
          });
        } else {
          // new form, set student info
          this.setState({
            studentID: _id,
            studentName,
            loading: false,
          });
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        this.setState({
          loading: false,
          error: true,
        });
      });
  }

  saveForm(e, newStatus) {
    e.preventDefault();
    e.target.blur();
    window.scrollTo(0, 0);

    const {
      match: { params },
    } = this.props;
    // const url = 'https://llu-api.modemtones.com/form/update';
    const url = `${process.env.REACT_APP_API_URL}/form/update`;

    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    const id = params.formid;
    const {
      formData,
      ethnicitySelect,
      raceSelect,
      interviewScore,
      undergraduateInstitution,
      undergraduateDegree,
      undergraduateMajor,
      undergraduateDegreeYear,
      undergraduateGPA,
      graduateInstitution,
      graduateDegree,
      graduateMajor,
      graduateDegreeYear,
      graduateGPA,
      greVerbalScore,
      grePercentile,
      greQuantitativeScore,
      greQuantitativePercentile,
      greAnalyticalWritingScore,
      greAnalyticalWritingPercentile,
      greAdvPsyScore,
      greAdvPsyPercentile,
      religion,
      studentID,
    } = this.state;

    // * Demographics
    // set qs from inputs
    formData[0].data[1].value = ethnicitySelect;
    formData[0].data[2].value = raceSelect;
    formData[0].data[6].value = interviewScore;

    // * Undergraduate Education
    // set qs from inputs
    formData[1].data[0].value = undergraduateInstitution;
    formData[1].data[1].value = undergraduateDegree;
    formData[1].data[2].value = undergraduateMajor;
    formData[1].data[3].value = undergraduateDegreeYear;
    formData[1].data[4].value = undergraduateGPA;

    // * Graduate Education
    // set qs from inputs
    formData[2].data[0].value = graduateInstitution;
    formData[2].data[1].value = graduateDegree;
    formData[2].data[2].value = graduateMajor;
    formData[2].data[3].value = graduateDegreeYear;
    formData[2].data[4].value = graduateGPA;

    // * GRE Information
    // set qs from inputs
    formData[3].data[0].value[0].value = greVerbalScore;
    formData[3].data[0].value[1].value = grePercentile;
    formData[3].data[1].value[0].value = greQuantitativeScore;
    formData[3].data[1].value[1].value = greQuantitativePercentile;
    formData[3].data[2].value[0].value = greAnalyticalWritingScore;
    formData[3].data[2].value[1].value = greAnalyticalWritingPercentile;
    formData[3].data[3].value[0].value = greAdvPsyScore;
    formData[3].data[3].value[1].value = greAdvPsyPercentile;

    // * Other Professional Activities
    // set religion if SDA === 'No'
    if (formData[4].data[0].value === 'No') {
      formData[4].data[1].value = religion;
    } else {
      formData[4].data[1].value = '';
    }

    axios
      .post(url, { id, newStatus, formData }, config)
      .then((response) => {
        // update form status if results are valid
        const { status } = response.data;
        if (status === 'completed') {
          // eslint-disable-next-line react/prop-types, react/destructuring-assignment
          this.props.history.push(`/student/${studentID}`);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }

  validateGPA(e) {
    const { target } = e;
    const { name } = target;
    let { value } = target;

    let gpaError = false;
    let gpaErrorMsg = '';
    // let { GPA } = this.state;
    const legalValue = /^[0-4][.][0-9][0-9]$/;

    if (value.length === 3) {
      value = `${value}0`;
    }

    if (value === '') {
      gpaError = true;
      gpaErrorMsg = 'GPA cannot be blank.';
    } else if (!legalValue.test(value)) {
      gpaError = true;
      gpaErrorMsg = 'Please enter a valid 3 digit GPA';
    }

    if (name === 'undergraduateGPA') {
      this.setState({
        undergraduateGPAError: gpaError,
        undergraduateGPAErrorMsg: gpaErrorMsg,
        [name]: value,
      });
    } else if (name === 'graduateGPA') {
      this.setState({
        graduateGPAError: gpaError,
        graduateGPAErrorMsg: gpaErrorMsg,
        [name]: value,
      });
    }
  }

  renderDemographics() {
    const { formData, ethnicitySelect, raceSelect, interviewScore } = this.state;

    return (
      <div>
        {/* Demographics */}
        <Row>
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Demographics
                </h5>
              }
            >
              <FormGroup>
                <Form>
                  {/* Demographics - Gender */}
                  <Row className="mt-4">
                    <Label sm={4} className="text-md-right">
                      Gender
                    </Label>
                    <Col sm={8}>
                      <Button
                        className="btn-form"
                        id="gender-b-female"
                        color={formData[0].data[0].value === 'Female' ? 'dark' : 'inverse'}
                        onClick={(e) => this.onFrmBtnClick(e, 0, 0, 'Female')}
                        active={formData[0].data[0].value === 'Female'}
                      >
                        Female
                      </Button>
                      <Button
                        className="btn-form"
                        id="gender-b-male"
                        color={formData[0].data[0].value === 'Male' ? 'dark' : 'inverse'}
                        onClick={(e) => this.onFrmBtnClick(e, 0, 0, 'Male')}
                        active={formData[0].data[0].value === 'Male'}
                      >
                        Male
                      </Button>
                      <Button
                        className="btn-form"
                        id="gender-b-Non-binary"
                        color={formData[0].data[0].value === 'Non-binary' ? 'dark' : 'inverse'}
                        onClick={(e) => this.onFrmBtnClick(e, 0, 0, 'Non-binary')}
                        active={formData[0].data[0].value === 'Non-binary'}
                      >
                        Non-binary
                      </Button>
                      <Button
                        className="btn-form"
                        id="gender-b-other"
                        color={formData[0].data[0].value === 'Other' ? 'dark' : 'inverse'}
                        onClick={(e) => this.onFrmBtnClick(e, 0, 0, 'Other')}
                        active={formData[0].data[0].value === 'Other'}
                      >
                        Other
                      </Button>
                    </Col>
                  </Row>
                  {/* // Demographics - Gender */}
                  {/* Demographics - Ethnicity */}
                  <Row className="mt-4">
                    <Col sm={4} className="text-md-right">
                      <Label for="ethnicitySelect">Ethnicity</Label>
                    </Col>
                    <Col sm={6}>
                      <Input
                        type="select"
                        name="ethnicitySelect"
                        id="ethnicitySelect"
                        value={ethnicitySelect}
                        onChange={this.handleInputChange}
                      >
                        <option>Hispanic/Latino</option>
                        <option>non-Hispanic/non-Latino</option>
                      </Input>
                    </Col>
                  </Row>
                  {/* // Demographics - Ethnicity */}
                  {/* Demographics - Race */}
                  <Row className="mt-4">
                    <Col sm={4} className="text-md-right">
                      <Label for="raceSelect">Race</Label>
                    </Col>
                    <Col sm={6}>
                      <Input
                        type="select"
                        name="raceSelect"
                        id="raceSelect"
                        value={raceSelect}
                        onChange={this.handleInputChange}
                      >
                        <option>American Indian</option>
                        <option>Alaskan Native</option>
                        <option>Black or African American</option>
                        <option>Native Hawaiian</option>
                        <option>Pacific Islander</option>
                        <option>Korean, Japanese, or Chinese</option>
                        <option>Filipino</option>
                        <option>Middle Eastern, Persian, Arab</option>
                        <option>Southeast Asian (e.g., Hmong, Thai, Vietnamese)</option>
                        <option>South or Central Asian (e.g., Indian, Pakistani)</option>
                        <option>White</option>
                        <option>Other</option>
                        <option>Not Reported</option>
                      </Input>
                    </Col>
                  </Row>
                  {/* // Demographics - Race */}
                  {/* Demographics - 1stGenCollege */}
                  <Row className="mt-4">
                    <Col className="text-center">
                      <Label>Is the student 1st generation college?</Label>
                      <br />
                      <Button
                        className="btn-form"
                        id="1stGenCollege-b-yes"
                        color={formData[0].data[3].value === 'Yes' ? 'dark' : 'inverse'}
                        onClick={(e) => this.onFrmBtnClick(e, 0, 3, 'Yes')}
                        active={formData[0].data[3].value === 'Yes'}
                      >
                        Yes
                      </Button>
                      <Button
                        className="btn-form"
                        id="1stGenCollege-b-no"
                        color={formData[0].data[3].value === 'No' ? 'dark' : 'inverse'}
                        onClick={(e) => this.onFrmBtnClick(e, 0, 3, 'No')}
                        active={formData[0].data[3].value === 'No'}
                      >
                        No
                      </Button>
                    </Col>
                  </Row>
                  {/* Demographics - 1stGenCollege */}
                  {/* Demographics - subjectToADA */}
                  <Row className="mt-4">
                    <Col className="text-center">
                      <Label>Is the student subject to ADA?</Label>
                      <br />
                      <Button
                        className="btn-form"
                        id="subjectToADA-b-yes"
                        color={formData[0].data[4].value === 'Yes' ? 'dark' : 'inverse'}
                        onClick={(e) => this.onFrmBtnClick(e, 0, 4, 'Yes')}
                        active={formData[0].data[4].value === 'Yes'}
                      >
                        Yes
                      </Button>
                      <Button
                        className="btn-form"
                        id="subjectToADA-b-no"
                        color={formData[0].data[4].value === 'No' ? 'dark' : 'inverse'}
                        onClick={(e) => this.onFrmBtnClick(e, 0, 4, 'No')}
                        active={formData[0].data[4].value === 'No'}
                      >
                        No
                      </Button>
                    </Col>
                  </Row>
                  {/* // Demographics - subjectToADA */}
                  {/* Demographics - foreignNational */}
                  <Row className="mt-4">
                    <Col className="text-center">
                      <Label>Is the student a foreign national?</Label>
                      <br />
                      <Button
                        className="btn-form"
                        id="foreignNational-b-yes"
                        color={formData[0].data[5].value === 'Yes' ? 'dark' : 'inverse'}
                        onClick={(e) => this.onFrmBtnClick(e, 0, 5, 'Yes')}
                        active={formData[0].data[5].value === 'Yes'}
                      >
                        Yes
                      </Button>
                      <Button
                        className="btn-form"
                        id="foreignNational-b-no"
                        color={formData[0].data[5].value === 'No' ? 'dark' : 'inverse'}
                        onClick={(e) => this.onFrmBtnClick(e, 0, 5, 'No')}
                        active={formData[0].data[5].value === 'No'}
                      >
                        No
                      </Button>
                    </Col>
                  </Row>
                  {/* // Demographics - foreignNational */}
                  {/* Demographics - interviewScore */}
                  <Row className="mt-4">
                    <Col className="text-center">
                      <Label className="text-md-right">Interview score (0 - 100)</Label>
                      <br />
                      <Input
                        className="textbox-width"
                        type="tel"
                        id="t-interviewScore"
                        name="interviewScore"
                        value={interviewScore}
                        onChange={this.handleNumberInputChange}
                        pattern="[0-9]+"
                      />
                    </Col>
                  </Row>
                  {/* // Demographics - interviewScore */}
                  <FormGroup row className="submitBlock mt-4">
                    <Col md={12} className="text-center">
                      <Button
                        className="ml-xs"
                        id="s0-b-save"
                        color="info"
                        onClick={(e) => this.saveForm(e, 'inProgress')}
                      >
                        Save
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s0-b-cont"
                        color="info"
                        onClick={(e) => this.onSectionBtnClick(e, 1)}
                      >
                        Continue
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }

  renderUndergraduateEducation() {
    const {
      undergraduateInstitution,
      undergraduateDegree,
      undergraduateMajor,
      undergraduateDegreeYear,
      undergraduateGPA,
      undergraduateGPAError,
      undergraduateGPAErrorMsg,
    } = this.state;

    return (
      <div>
        {/* Undergraduate Education */}
        <Row>
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Undergraduate Education
                </h5>
              }
            >
              <FormGroup>
                <Form>
                  {/* Undergraduate Education - undergraduateInstitution */}
                  <Row className="mt-4">
                    <Label md={4} className="text-md-right">
                      Undergraduate Institution
                    </Label>
                    <Col md={6}>
                      <Input
                        type="text"
                        id="t-undergraduateInstitution"
                        placeholder="ex. UCI - University of California, Irvine"
                        name="undergraduateInstitution"
                        value={undergraduateInstitution}
                        onChange={this.handleInputChange}
                      />
                    </Col>
                  </Row>
                  {/* // Undergraduate Education - undergraduateInstitution */}
                  {/* Undergraduate Education - undergraduateDegree */}
                  <Row className="mt-4">
                    <Label md={4} className="text-md-right">
                      Undergraduate Degree
                    </Label>
                    <Col md={6}>
                      <Input
                        type="text"
                        id="t-undergraduateDegree"
                        name="undergraduateDegree"
                        value={undergraduateDegree}
                        onChange={this.handleInputChange}
                      />
                    </Col>
                  </Row>
                  {/* // Undergraduate Education - undergraduateDegree */}
                  {/* Undergraduate Education - undergraduateMajor */}
                  <Row className="mt-4">
                    <Label md={4} className="text-md-right">
                      Undergraduate Major
                    </Label>
                    <Col md={6}>
                      <Input
                        type="text"
                        id="t-undergraduateMajor"
                        name="undergraduateMajor"
                        value={undergraduateMajor}
                        onChange={this.handleInputChange}
                      />
                    </Col>
                  </Row>
                  {/* // Undergraduate Education - undergraduateMajor */}
                  {/* Undergraduate Education - undergraduateDegreeYear  + undergraduateGPA */}
                  <FormGroup>
                    <Row className="mt-4">
                      <Col sm={6}>
                        <FormGroup row>
                          <Label xs={8} className="text-right label-sm">
                            Year Degree Awarded
                          </Label>
                          <Col sm={3}>
                            <Input
                              className="textbox-width"
                              type="tel"
                              id="t-undergraduateDegreeYear"
                              name="undergraduateDegreeYear"
                              value={undergraduateDegreeYear}
                              onChange={this.handleNumberInputChange}
                              pattern="[0-9]+"
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup row>
                          <Label xs={8} className="text-right label-sm">
                            Undergraduate GPA
                          </Label>
                          <Col sm={3}>
                            <Input
                              type="text"
                              id="t-undergraduateGPA"
                              className="textbox-width"
                              name="undergraduateGPA"
                              value={undergraduateGPA}
                              onChange={this.handleInputChange}
                              onBlur={this.validateGPA}
                              invalid={undergraduateGPAError}
                            />
                            <FormFeedback>{undergraduateGPAErrorMsg}</FormFeedback>
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                  </FormGroup>
                  {/* // Undergraduate Education - yearDegreeAwarded  + undergraduateGPA */}
                  <FormGroup row className="submitBlock mt-4">
                    <Col md={12} className="text-center">
                      <Button
                        className="ml-xs"
                        id="s1-b-pre"
                        color="inverse"
                        onClick={(e) => this.onSectionBtnClick(e, -1)}
                      >
                        Previous
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s1-b-save"
                        color="info"
                        onClick={(e) => this.saveForm(e, 'inProgress')}
                      >
                        Save
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s1-b-cont"
                        color="info"
                        onClick={(e) => this.onSectionBtnClick(e, 1)}
                      >
                        Continue
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }

  renderGraduateEducation() {
    const {
      graduateInstitution,
      graduateDegree,
      graduateMajor,
      graduateDegreeYear,
      graduateGPA,
      graduateGPAError,
      graduateGPAErrorMsg,
    } = this.state;

    return (
      <div>
        {/* Graduate Education */}
        <Row>
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Graduate Education
                </h5>
              }
            >
              <FormGroup>
                <Form>
                  {/* Graduate Education - graduateInstitution */}
                  <Row className="mt-4">
                    <Label md={4} className="text-md-right">
                      Graduate Institution
                    </Label>
                    <Col md={6}>
                      <Input
                        type="text"
                        id="t-graduateInstitution"
                        placeholder="ex. UCI - University of California, Irvine"
                        name="graduateInstitution"
                        value={graduateInstitution}
                        onChange={this.handleInputChange}
                      />
                    </Col>
                  </Row>
                  {/* // Graduate Education - graduateInstitution */}
                  {/* Graduate Education - graduateDegree */}
                  <Row className="mt-4">
                    <Label md={4} className="text-md-right">
                      Graduate Degree
                    </Label>
                    <Col md={6}>
                      <Input
                        type="text"
                        id="t-graduateDegree"
                        name="graduateDegree"
                        value={graduateDegree}
                        onChange={this.handleInputChange}
                      />
                    </Col>
                  </Row>
                  {/* // Graduate Education - graduateDegree */}
                  {/* Graduate Education - graduateMajor */}
                  <Row className="mt-4">
                    <Label md={4} className="text-md-right">
                      Graduate Major
                    </Label>
                    <Col md={6}>
                      <Input
                        type="text"
                        id="t-graduateMajor"
                        name="graduateMajor"
                        value={graduateMajor}
                        onChange={this.handleInputChange}
                      />
                    </Col>
                  </Row>
                  {/* // Graduate Education - graduateMajor */}
                  {/* Graduate Education - graduateDegreeYear  + graduateGPA */}
                  <FormGroup>
                    <Row className="mt-4">
                      <Col sm={6}>
                        <FormGroup row>
                          <Label xs={8} className="text-right label-sm">
                            Year Degree Awarded
                          </Label>
                          <Col sm={3}>
                            <Input
                              className="textbox-width"
                              type="tel"
                              id="t-graduateDegreeYear"
                              name="graduateDegreeYear"
                              value={graduateDegreeYear}
                              onChange={this.handleNumberInputChange}
                              pattern="[0-9]+"
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup row>
                          <Label xs={8} className="text-right label-sm">
                            Graduate GPA
                          </Label>
                          <Col sm={3}>
                            <Input
                              type="text"
                              id="t-graduateGPA"
                              className="textbox-width"
                              name="graduateGPA"
                              value={graduateGPA}
                              onChange={this.handleInputChange}
                              onBlur={this.validateGPA}
                              invalid={graduateGPAError}
                            />
                            <FormFeedback>{graduateGPAErrorMsg}</FormFeedback>
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                  </FormGroup>
                  {/* // Graduate Education - yearDegreeAwarded  + undergraduateGPA */}
                  <FormGroup row className="submitBlock mt-4">
                    <Col md={12} className="text-center">
                      <Button
                        className="ml-xs"
                        id="s2-b-pre"
                        color="inverse"
                        onClick={(e) => this.onSectionBtnClick(e, -1)}
                      >
                        Previous
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s2-b-save"
                        color="info"
                        onClick={(e) => this.saveForm(e, 'inProgress')}
                      >
                        Save
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s2-b-cont"
                        color="info"
                        onClick={(e) => this.onSectionBtnClick(e, 1)}
                      >
                        Continue
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }

  renderGREInformation() {
    const {
      greVerbalScore,
      grePercentile,
      greQuantitativeScore,
      greQuantitativePercentile,
      greAnalyticalWritingScore,
      greAnalyticalWritingPercentile,
      greAdvPsyScore,
      greAdvPsyPercentile,
    } = this.state;

    return (
      <div>
        {/* GRE Information */}
        <Row>
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> GRE Information
                </h5>
              }
            >
              <FormGroup>
                <Form>
                  {/* GRE Information - Verbal */}
                  <FormGroup row className="mt-4">
                    <Label md={4} className="text-md-right">
                      <b>GRE Verbal</b>
                    </Label>
                    <Col md={7}>
                      <Row>
                        <Col sm={6}>
                          <FormGroup row>
                            <Label sm={3} className="text-md-right">
                              Score
                            </Label>
                            <Col sm={6}>
                              <Input
                                className="textbox-width"
                                type="tel"
                                id="t-greVerbalScore"
                                name="greVerbalScore"
                                value={greVerbalScore}
                                onChange={this.handleNumberInputChange}
                                pattern="[0-9]+"
                              />
                            </Col>
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup row>
                            <Label sm={5} className="text-md-right">
                              Percentile
                            </Label>
                            <Col sm={5}>
                              <Input
                                className="textbox-width"
                                type="tel"
                                id="t-grePercentile"
                                name="grePercentile"
                                value={grePercentile}
                                onChange={this.handleNumberInputChange}
                                pattern="[0-9]+"
                              />
                            </Col>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>
                  {/* // GRE Information - Verbal */}
                  {/* GRE Information - Quantitative */}
                  <FormGroup row className="mt-4">
                    <Label md={4} className="text-md-right">
                      <b>GRE Quantitative</b>
                    </Label>
                    <Col md={7}>
                      <Row>
                        <Col sm={6}>
                          <FormGroup row>
                            <Label sm={3} className="text-md-right">
                              Score
                            </Label>
                            <Col sm={6}>
                              <Input
                                className="textbox-width"
                                type="tel"
                                id="t-greQuantitativeScore"
                                name="greQuantitativeScore"
                                value={greQuantitativeScore}
                                onChange={this.handleNumberInputChange}
                                pattern="[0-9]+"
                              />
                            </Col>
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup row>
                            <Label sm={5} className="text-md-right">
                              Percentile
                            </Label>
                            <Col sm={5}>
                              <Input
                                className="textbox-width"
                                type="tel"
                                id="t-greQuantitativePercentile"
                                name="greQuantitativePercentile"
                                value={greQuantitativePercentile}
                                onChange={this.handleNumberInputChange}
                                pattern="[0-9]+"
                              />
                            </Col>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>
                  {/* // GRE Information - Quantitative */}
                  {/* GRE Information - Analytical Writing */}
                  <FormGroup row className="mt-4">
                    <Label md={4} className="text-md-right">
                      <b>GRE Analytical Writing</b>
                    </Label>
                    <Col md={7}>
                      <Row>
                        <Col sm={6}>
                          <FormGroup row>
                            <Label sm={3} className="text-md-right">
                              Score
                            </Label>
                            <Col sm={6}>
                              <Input
                                className="textbox-width"
                                type="tel"
                                id="t-greAnalyticalWritingScore"
                                name="greAnalyticalWritingScore"
                                value={greAnalyticalWritingScore}
                                onChange={this.handleNumberInputChange}
                                pattern="[0-9]+"
                              />
                            </Col>
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup row>
                            <Label sm={5} className="text-md-right">
                              Percentile
                            </Label>
                            <Col sm={5}>
                              <Input
                                className="textbox-width"
                                type="tel"
                                id="t-greAnalyticalWritingPercentile"
                                name="greAnalyticalWritingPercentile"
                                value={greAnalyticalWritingPercentile}
                                onChange={this.handleNumberInputChange}
                                pattern="[0-9]+"
                              />
                            </Col>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>
                  {/* // GRE Information - Analytical Writing */}
                  {/* GRE Information - Advanced Psychology */}
                  <FormGroup row className="mt-4">
                    <Label md={4} className="text-md-right">
                      <b>GRE Advanced Psychology</b>
                    </Label>
                    <Col md={7}>
                      <Row>
                        <Col sm={6}>
                          <FormGroup row>
                            <Label sm={3} className="text-md-right">
                              Score
                            </Label>
                            <Col sm={6}>
                              <Input
                                className="textbox-width"
                                type="tel"
                                id="t-greAdvPsyScore"
                                name="greAdvPsyScore"
                                value={greAdvPsyScore}
                                onChange={this.handleNumberInputChange}
                                pattern="[0-9]+"
                              />
                            </Col>
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup row>
                            <Label sm={5} className="text-md-right">
                              Percentile
                            </Label>
                            <Col sm={5}>
                              <Input
                                className="textbox-width"
                                type="tel"
                                id="t-greAdvPsyPercentile"
                                name="greAdvPsyPercentile"
                                value={greAdvPsyPercentile}
                                onChange={this.handleNumberInputChange}
                                pattern="[0-9]+"
                              />
                            </Col>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>
                  {/* // GRE Information - Advanced Psychology */}
                  <FormGroup row className="submitBlock mt-4">
                    <Col md={12} className="text-center">
                      <Button
                        className="ml-xs"
                        id="s3-b-pre"
                        color="inverse"
                        onClick={(e) => this.onSectionBtnClick(e, -1)}
                      >
                        Previous
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s3-b-save"
                        color="info"
                        onClick={(e) => this.saveForm(e, 'inProgress')}
                      >
                        Save
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s3-b-cont"
                        color="info"
                        onClick={(e) => this.onSectionBtnClick(e, 1)}
                      >
                        Continue
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }

  renderReligion() {
    const { formData } = this.state;
    let { religion } = this.state;

    if (formData[4].data[0].value === 'Yes') {
      religion = '';
    }

    return (
      <div>
        {/* Religious Affiliation */}
        <Row>
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Religious Affiliation
                </h5>
              }
            >
              <FormGroup>
                <Form>
                  {/* Religious Affiliation - SDA */}
                  <Row className="mt-4">
                    <Col className="text-center">
                      <Label>Is the student SDA?</Label>
                      <br />
                      <Button
                        className="btn-form"
                        id="sda-b-yes"
                        color={formData[4].data[0].value === 'Yes' ? 'dark' : 'inverse'}
                        onClick={(e) => this.onFrmBtnClick(e, 4, 0, 'Yes')}
                        active={formData[4].data[0].value === 'Yes'}
                      >
                        Yes
                      </Button>
                      <Button
                        className="btn-form"
                        id="sda-b-no"
                        color={formData[4].data[0].value === 'No' ? 'dark' : 'inverse'}
                        onClick={(e) => this.onFrmBtnClick(e, 4, 0, 'No')}
                        active={formData[4].data[0].value === 'No'}
                      >
                        No
                      </Button>
                    </Col>
                  </Row>
                  {/* // Religious Affiliation - SDA */}
                  {/* Religious Affiliation - Religion */}
                  <FormGroup>
                    <Row className="justify-content-center mt-4">
                      <Col md={6} className="text-center">
                        <Label>If not SDA, Religion:</Label>
                        <br />
                        <Input
                          type="text"
                          id="t-religion"
                          name="religion"
                          value={religion}
                          onChange={this.handleInputChange}
                          disabled={formData[4].data[0].value === 'Yes'}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  {/* // Religious Affiliation - Religion */}
                  <FormGroup row className="submitBlock mt-4">
                    <Col md={12} className="text-center">
                      <Button
                        className="ml-xs"
                        id="s4-b-pre"
                        color="inverse"
                        onClick={(e) => this.onSectionBtnClick(e, -1)}
                      >
                        Previous
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s4-b-save"
                        color="info"
                        onClick={(e) => this.saveForm(e, 'inProgress')}
                      >
                        Save
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s4-b-submit"
                        color="info"
                        onClick={(e) => this.saveForm(e, 'completed')}
                      >
                        Submit
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }

  renderReadout() {
    const {
      formData,
      ethnicitySelect,
      raceSelect,
      interviewScore,
      undergraduateInstitution,
      undergraduateDegree,
      undergraduateMajor,
      undergraduateDegreeYear,
      undergraduateGPA,
      graduateInstitution,
      graduateDegree,
      graduateMajor,
      graduateDegreeYear,
      graduateGPA,
      greVerbalScore,
      grePercentile,
      greQuantitativeScore,
      greQuantitativePercentile,
      greAnalyticalWritingScore,
      greAnalyticalWritingPercentile,
      greAdvPsyScore,
      greAdvPsyPercentile,
      religion,
    } = this.state;

    return (
      <div>
        <Row>
          <Col>
            {/* Demographics */}
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Demographics
                </h5>
              }
            >
              <FormGroup>
                <Form>
                  {/* Demographics - Gender */}
                  <Row className="mt-4 ml-3">
                    <Col>
                      <p className="readout">
                        Gender: <b className="ml-2">{formData[0].data[0].value}</b>
                      </p>
                    </Col>
                  </Row>
                  {/* // Demographics - Gender */}
                  {/* Demographics - Ethnicity */}
                  <Row className="mt-4 ml-3">
                    <Col>
                      <p className="readout">
                        Ethnicity: <b className="ml-2">{ethnicitySelect}</b>
                      </p>
                    </Col>
                  </Row>
                  {/* // Demographics - Ethnicity */}
                  {/* Demographics - Race */}
                  <Row className="mt-4 ml-3">
                    <Col>
                      <p className="readout">
                        Race: <b className="ml-2">{raceSelect}</b>
                      </p>
                    </Col>
                  </Row>
                  {/* // Demographics - Race */}
                  {/* Demographics - 1stGenCollege */}
                  <Row className="mt-4 ml-3">
                    <Col>
                      <p className="readout">
                        Is the student 1st generation college:{' '}
                        <b className="ml-2">{formData[0].data[3].value}</b>
                      </p>
                    </Col>
                  </Row>
                  {/* Demographics - 1stGenCollege */}
                  {/* Demographics - subjectToADA */}
                  <Row className="mt-4 ml-3">
                    <Col>
                      <p className="readout">
                        Is the student subject to ADA:{' '}
                        <b className="ml-2">{formData[0].data[4].value}</b>
                      </p>
                    </Col>
                  </Row>
                  {/* // Demographics - subjectToADA */}
                  {/* Demographics - foreignNational */}
                  <Row className="mt-4 ml-3">
                    <Col>
                      <p className="readout">
                        Is the student a foreign national:{' '}
                        <b className="ml-2">{formData[0].data[5].value}</b>
                      </p>
                    </Col>
                  </Row>
                  {/* // Demographics - foreignNational */}
                  {/* Demographics - interviewScore */}
                  <Row className="mt-4 ml-3">
                    <Col>
                      <p className="readout">
                        Interview score: <b className="ml-2">{interviewScore}</b>
                      </p>
                    </Col>
                  </Row>
                  {/* // Demographics - interviewScore */}
                </Form>
              </FormGroup>
            </Widget>
            {/* Undergraduate Education */}
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Undergraduate Education
                </h5>
              }
            >
              <FormGroup>
                <Form>
                  {/* Undergraduate Education - undergraduateInstitution */}
                  <Row className="mt-4 ml-3">
                    <Col>
                      <p className="readout">
                        Undergraduate Institution:{' '}
                        <b className="ml-2">{undergraduateInstitution}</b>
                      </p>
                    </Col>
                  </Row>
                  {/* // Undergraduate Education - undergraduateInstitution */}
                  {/* Undergraduate Education - undergraduateDegree */}
                  <Row className="mt-4 ml-3">
                    <Col>
                      <p className="readout">
                        Undergraduate Degree: <b className="ml-2">{undergraduateDegree}</b>
                      </p>
                    </Col>
                  </Row>
                  {/* // Undergraduate Education - undergraduateDegree */}
                  {/* Undergraduate Education - undergraduateMajor */}
                  <Row className="mt-4 ml-3">
                    <Col>
                      <p className="readout">
                        Undergraduate Major: <b className="ml-2">{undergraduateMajor}</b>
                      </p>
                    </Col>
                  </Row>
                  {/* // Undergraduate Education - undergraduateMajor */}
                  {/* Undergraduate Education - undergraduateDegreeYear  + undergraduateGPA */}
                  <Row className="mt-4 ml-3">
                    <Col>
                      <p className="readout">
                        Year Degree Awarded: <b className="ml-2">{undergraduateDegreeYear}</b>
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-4 ml-3">
                    <Col>
                      <p className="readout">
                        Undergraduate GPA: <b className="ml-2">{undergraduateGPA}</b>
                      </p>
                    </Col>
                  </Row>
                  {/* // Undergraduate Education - yearDegreeAwarded  + undergraduateGPA */}
                </Form>
              </FormGroup>
            </Widget>
            {/* Graduate Education */}
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Graduate Education
                </h5>
              }
            >
              {/* Graduate Education - graduateInstitution */}
              <Row className="mt-4 ml-3">
                <Col>
                  <p className="readout">
                    Graduate Institution: <b className="ml-2">{graduateInstitution}</b>
                  </p>
                </Col>
              </Row>
              {/* // Graduate Education - graduateInstitution */}
              {/* Graduate Education - graduateDegree */}
              <Row className="mt-4 ml-3">
                <Col>
                  <p className="readout">
                    Graduate Degree: <b className="ml-2">{graduateDegree}</b>
                  </p>
                </Col>
              </Row>
              {/* // Graduate Education - graduateDegree */}
              {/* Graduate Education - graduateMajor */}
              <Row className="mt-4 ml-3">
                <Col>
                  <p className="readout">
                    Graduate Major: <b className="ml-2">{graduateMajor}</b>
                  </p>
                </Col>
              </Row>
              {/* // Graduate Education - graduateMajor */}
              {/* Graduate Education - graduateDegreeYear  + graduateGPA */}
              <Row className="mt-4 ml-3">
                <Col>
                  <p className="readout">
                    Year Degree Awarded: <b className="ml-2">{graduateDegreeYear}</b>
                  </p>
                </Col>
              </Row>
              <Row className="mt-4 ml-3">
                <Col>
                  <p className="readout">
                    Graduate GPA: <b className="ml-2">{graduateGPA}</b>
                  </p>
                </Col>
              </Row>
              {/* // Graduate Education - yearDegreeAwarded  + undergraduateGPA */}
            </Widget>
            {/* GRE Information */}
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Graduate Education
                </h5>
              }
            >
              {/* GRE Information - Verbal */}
              <Row className="mt-4 ml-3">
                <Col xs={12}>
                  <p className="readout">
                    <u>
                      <b>GRE Verbal</b>
                    </u>
                  </p>
                </Col>
                <Col sm={4} md={3} lg={2}>
                  <p className="readout">
                    Score: <b className="ml-2">{greVerbalScore}</b>
                  </p>
                </Col>
                <Col sm={4} md={3} lg={2}>
                  <p className="readout">
                    Percentile: <b className="ml-2">{grePercentile}</b>
                  </p>
                </Col>
              </Row>
              {/* // GRE Information - Verbal */}
              {/* GRE Information - Quantitative */}
              <Row className="mt-4 ml-3">
                <Col xs={12}>
                  <p className="readout">
                    <u>
                      <b>GRE Quantitative</b>
                    </u>
                  </p>
                </Col>
                <Col sm={4} md={3} lg={2}>
                  <p className="readout">
                    Score: <b className="ml-2">{greQuantitativeScore}</b>
                  </p>
                </Col>
                <Col sm={4} md={3} lg={2}>
                  <p className="readout">
                    Percentile: <b className="ml-2">{greQuantitativePercentile}</b>
                  </p>
                </Col>
              </Row>
              {/* // GRE Information - Quantitative */}
              {/* GRE Information - Analytical Writing */}
              <Row className="mt-4 ml-3">
                <Col xs={12}>
                  <p className="readout">
                    <u>
                      <b>GRE Analytical Writing</b>
                    </u>
                  </p>
                </Col>
                <Col sm={4} md={3} lg={2}>
                  <p className="readout">
                    Score: <b className="ml-2">{greAnalyticalWritingScore}</b>
                  </p>
                </Col>
                <Col sm={4} md={3} lg={2}>
                  <p className="readout">
                    Percentile: <b className="ml-2">{greAnalyticalWritingPercentile}</b>
                  </p>
                </Col>
              </Row>
              {/* // GRE Information - Analytical Writing */}
              {/* GRE Information - Advanced Psychology */}
              <Row className="mt-4 ml-3">
                <Col xs={12}>
                  <p className="readout">
                    <u>
                      <b>GRE Advanced Psychology</b>
                    </u>
                  </p>
                </Col>
                <Col sm={4} md={3} lg={2}>
                  <p className="readout">
                    Score: <b className="ml-2">{greAdvPsyScore}</b>
                  </p>
                </Col>
                <Col sm={4} md={3} lg={2}>
                  <p className="readout">
                    Percentile: <b className="ml-2">{greAdvPsyPercentile}</b>
                  </p>
                </Col>
              </Row>
              {/* // GRE Information - Advanced Psychology */}
            </Widget>
            {/* Religious Affiliation */}
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Religious Affiliation
                </h5>
              }
            >
              {/* Religious Affiliation - SDA */}
              <Row className="mt-4 ml-3">
                <Col>
                  <p className="readout">
                    Is the student SDA: <b className="ml-2">{formData[4].data[0].value}</b>
                  </p>
                </Col>
              </Row>
              {/* // Religious Affiliation - SDA */}
              {/* Religious Affiliation - Religion */}
              <Row className="mt-4 ml-3">
                <Col>
                  <p className="readout">
                    If not SDA, Religion: <b className="ml-2">{religion}</b>
                  </p>
                </Col>
              </Row>
              {/* // Religious Affiliation - Religion */}
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }

  // eslint-disable-next-line consistent-return
  render() {
    const { loading, error, currentSection, studentID, studentName } = this.state;

    if (!loading && !error) {
      return (
        <div>
          <Row className="mt-4">
            <Col xs={{ size: 10, offset: 1 }}>
              <Row>
                <Col xs="12">
                  <div className="crumbs">
                    <Breadcrumb>
                      <BreadcrumbItem>
                        <Link to="/dashboard">Dashboard</Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem>
                        <Link to={`/student/${studentID}`}>{studentName}</Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem active>Student Admission Data</BreadcrumbItem>
                    </Breadcrumb>
                  </div>
                </Col>
              </Row>
              <h2 className="page-title">Student Admission Data: {studentName}</h2>
              {currentSection === 0 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={0} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 0 && this.renderDemographics()}
              {currentSection === 1 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={26} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 1 && this.renderUndergraduateEducation()}
              {currentSection === 2 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={44} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 2 && this.renderGraduateEducation()}
              {currentSection === 3 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={63} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 3 && this.renderGREInformation()}
              {currentSection === 4 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={93} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 4 && this.renderReligion()}
              {currentSection === 999 && this.renderReadout()}
            </Col>
          </Row>
        </div>
      );
    }
    if (loading) {
      return (
        <div>
          <Row className="mt-4">
            <Col xs={{ size: 10, offset: 1 }}>
              <h2 className="page-title">Student Admission Data</h2>
            </Col>
          </Row>
          <Row className="text-center">
            <Col>
              <img src={loadingSpinner} alt="loading spinner" className="img-responsive" />
            </Col>
          </Row>
        </div>
      );
    }
    if (error) {
      return (
        <div>
          <Row className="mt-4">
            <Col xs={{ size: 10, offset: 1 }}>
              <Widget>
                <Row className="mt-4 mb-4 text-center">
                  <Col>
                    <h4>
                      There was an error loading the form, please reload the page and try again.
                    </h4>
                  </Col>
                </Row>
              </Widget>
            </Col>
          </Row>
        </div>
      );
    }
  }
}

SAD.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withRouter(SAD);
